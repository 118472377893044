.admin-new-user {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px;

  .container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .box {
      width: 20vw;
      min-width: 270px;
      padding-bottom: 10vh;

      .title {
        font-size: 2em;
        text-align: center;
        width: 100%;
      }

      .subtitle {
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
      }

      .input-title {
        width: 100%;
        padding-left: 2px;
      }

      .text-input {
        width: 100%;
        text-align: center;
      }

      .sites {
        min-height: 150px;
        padding: 5px;
        height: 20vh;
        border: 1px solid lightgray;
        overflow: auto;
      }

      .create-user-button {
        width: 100%;
        background-color: #38A2E8;
        border-color: #38A2E8;
      }
    }

  }
}