.forgot-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px;

  .header {
    align-self: flex-start;
    display: flex;
    width: 100vw;
    min-width: 665px;

    .header-left {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 50%;
      padding: 20px;

      img {
        object-fit: contain;
        max-height: 75px;
      }
    }

    .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
      padding: 20px;

      img {
        object-fit: contain;
        max-height: 75px;
      }
    }
  }

  .forgot-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;


    .forgot-box {
      width: 20vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .forgot-title {
        text-align: center;
        font-size: 2em;
        margin: 5px;
      }
  
      .forgot-text {
        text-align: center;
        margin: 5px;
      }
  
      input {
        text-align: center;
        width: 100%;
        margin: 5px;
      }
  
      .reset-button {
        width: 100%;
        margin: 5px;
        background-color: #38A2E8;
        border-color: #38A2E8;
      }
    }
  }
}