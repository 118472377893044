.view-account {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    flex-direction: column;
    min-width: 665px;

    .loading-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: white;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 200000;

        .heart-rate {
            width: 150px;
            height: 73px;
            position: relative;
            margin: 20px auto;
        }

        .fade-in {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #FFFFFF;
            top: 0;
            right: 0;
            animation: heartRateIn 2.5s linear infinite;
        }

        .fade-out {
            position: absolute;
            width: 120%;
            height: 100%;
            top: 0;
            left: -120%;
            animation: heartRateOut 2.5s linear infinite;
            background: rgba(255, 255, 255, 1);
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);
        }
    }

    .container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        .box {
            width: auto;
            min-width: 270px;
            padding-bottom: 10vh;

            .title {
                font-size: 2em;
                text-align: center;
                width: 100%;
            }

            .subtitle {
                text-align: center;
                width: 100%;
                margin-bottom: 10px;
            }

            .input-title {
                width: 100%;
                padding-left: 2px;
            }

            .text-input {
                width: 100%;
                text-align: center;
            }

            .sites {
                min-height: 150px;
                padding: 5px;
                height: 15vh;
                border: 1px solid lightgray;
                overflow: auto;
            }

            .create-user-button {
                width: 100%;
                background-color: #38A2E8;
                border-color: #38A2E8;
            }

            #disabled {
                color: grey;
            }
        }
    }
}
