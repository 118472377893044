.login-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  min-width: 665px;

  .header {
    align-self: flex-start;
    display: flex;
    width: 100vw;
    min-width: 665px;

    .header-left {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 50%;
      padding: 20px;

      img {
        object-fit: contain;
        max-height: 75px;
      }
    }

    .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
      padding: 20px;

      img {
        object-fit: contain;
        max-height: 75px;
      }
    }
  }
  
  .login-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .login-title {
      text-align: center;
      font-size: 2em;
      margin: 5px;
    }

    .login-text {
      text-align: center;
      margin: 5px;
    }

    .login-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 20vw;
      min-width: 270px;

      input {
        margin: 5px;
        text-align: center;
        
      }

      .login-button {
        margin: 5px;
        width: 100%;
        background-color: #38A2E8;
        border-color: #38A2E8;
      }
    }
  }
}