.home-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100vw;

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    .report {
        width: 100%;
        height: 99%;
    }

    #container {
        text-align: center;
        height: 100%;
        width: 100%;
    }

    .buttonContainer {
        align-self: flex-start;
        padding-left: 5px;
    }

    #fullscreen {
        color: #38A2E8;
        border-color: #38A2E8;
    }

    i:hover {
        background-color: #e5fbfb;
    }

    .loading-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: white;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 200000;

        .heart-rate {
            width: 150px;
            height: 73px;
            position: relative;
            margin: 20px auto;
        }

        .fade-in {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #FFFFFF;
            top: 0;
            right: 0;
            animation: heartRateIn 2.5s linear infinite;
        }

        .fade-out {
            position: absolute;
            width: 120%;
            height: 100%;
            top: 0;
            left: -120%;
            animation: heartRateOut 2.5s linear infinite;
            background: rgba(255, 255, 255, 1);
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);
        }

        @keyframes heartRateIn {
            0% {
                width: 100%;
            }

            50% {
                width: 0;
            }

            100% {
                width: 0;
            }
        }

        @keyframes heartRateOut {
            0% {
                left: -120%;
            }

            30% {
                left: -120%;
            }

            100% {
                left: 0;
            }
        }
    }
}
